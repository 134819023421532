:root{
  --color-black: #000;
  --color-white: #fff;
  --color-white-opacity-42: rgba(0, 0, 0, 0.42);
  --color-white-opacity-6: rgba(255, 255, 255, 0.60);
  --color-black-opacity-42: rgba(29, 24, 25, 0.42);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  width: 100vw;
  height: 100vh;
  display: flex;
  background: black;

  overflow: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*{
  box-sizing: border-box;
}

#root{
  display: flex;
  width: 100%;
  height: 100%;
  background: rgb(0,0,0,1);
  /*background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(0,0,0,1) 69%);*/
  overflow: hidden;
}


button {
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
}

a {
  display: flex;
  align-items: center;
  text-decoration: none;
}

p{
  margin: 0;
}


img {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
  /*pointer-events: none;*/
}
