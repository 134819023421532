@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.page{
    animation: fadeInAnimation ease 2s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;

    &::-webkit-scrollbar {
        width: 6px;
        height: 2px;
        border-radius: 6px;
        background: var(--color-black);
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 2px;
        background: var(--color-black-opacity-42);
    }

    @media (max-width: 1024px)  {
        margin-top:0;
    }
}