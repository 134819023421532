.button{
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--color-white-opacity-42);
    border-radius: 25%;

    @media (max-width: 1024px)  {
        width: 28px;
        height: 28px;
    }
}
