@font-face {
    font-family: 'Pretendard-SemiBold-w600-s16';
    font-style: normal;
    font-size: 400px;
    font-weight: 600;
    src: url("./assets/fonts/Pretendard-SemiBold.woff2")
}

@font-face {
    font-family: 'Pretendard-SemiBold-w600-s12';
    font-style: normal;
    font-size: 12px;
    font-weight: 600;
    src: url("./assets/fonts/Pretendard-SemiBold.woff2")
}

@font-face {
    font-family: 'Pretendard-SemiBold-w500-s14';
    font-style: normal;
    font-size: 14px;
    font-weight: 500;
    src: url("./assets/fonts/Pretendard-SemiBold.woff2")
}

@font-face {
    font-family: 'CutmarkBold';
    font-style: normal;
    font-size: 14px;
    font-weight: 500;
    src: url("./assets/fonts/CutmarkBold.otf")
}
