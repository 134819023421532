.container{
    display: flex;
    width: 228px;
    height: fit-content;
    padding: 0;
    margin-bottom: 0;
    gap:0;
}

.title{
    width: 181px;
    height: 36px;
    /*font-family: Pretendard;*/
    /*font-size: 100px;*/
    /*font-weight: 600;*/
    /*color: var(--color-white);*/
    margin: 0;
    padding: 0;

    animation: fadeInBottomLeft ease 2s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

/*.title::after{*/
/*    content: '';*/
/*    display: block;*/
/*    margin-top: 10px;*/
/*    margin-bottom: 50px;*/

/*    border: 1px solid var(--color-white-opacity-6);*/

/*    animation: fadeInBottomLeft ease 2s;*/
/*    animation-iteration-count: 1;*/
/*    animation-fill-mode: forwards;*/
/*}*/

@keyframes fadeInBottomLeft {
    0% {
        position: absolute;
        left: -200px;
        width: 183px;
    }
    50%{
        left: 0;
        width: 183px;
    }
    100% {
        left: 100px;
        width: 183px;
    }
}