.container{
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    width: fit-content;

    position: absolute;
    top: 44%;
    left: 0;
    right: 0;
    margin: auto;

    @media (max-width: 1024px)  {
        position: static;
        height: 100%;
        gap:20px;
        justify-content: space-between;
    }
}

.title{
    height: 83px;

    @media (max-width: 1024px)  {
        height: 35px;
        position: absolute;
        top: 37%;
        left: 0;
        right: 0;
        margin: auto;
    }
}

.description{
    text-align: center;
    font-family: Pretendard-SemiBold-w500-s14, serif;
    text-shadow: 0 4px 18px rgba(0, 0, 0, 0.65);
    color: var(--color-white);

    white-space: pre-wrap;

    @media (max-width: 1024px)  {
        font-size: 10px;
        color: var(--color-white-opacity-6);
        position: absolute;
        bottom: 12px;
        left: 0;
        right: 0;
        margin: auto;
    }
}