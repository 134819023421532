@import url("../../font.css");
.header{
    width: 100%;
    height: 82px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    position: fixed;
    top: 0;
    z-index: 1;

    @media (max-width: 1024px)  {
        height: 48px;
    }
}

.header.gallery{
    background: var(--color-black);

}

.logo{
    cursor: pointer;
    width: 150px;
    height: 50px;
    padding-left: 60px;
    @media (max-width: 1024px)  {
        width: 100px;
        height: 20px;
        padding-left: 16px;
    }
}

.buttonContainer{
    display: flex;
    gap: 36px;
    padding-right: 60px;

    @media (max-width: 1024px)  {
        gap: 20px;
        padding-right: 16px;
    }
}

.galleryButton{
    padding: 5px 23px;
    border-radius: 10px;
    background: var(--color-white-opacity-42);
    color: var(--color-white);
    font-family: Pretendard-SemiBold-w600-s16, serif;

    @media (max-width: 1024px)  {
        padding: 3px 16px;
        font-family: Pretendard-SemiBold-w600-s12, serif;
    }
}