.container{
    cursor: pointer;
    width: 25%;
    height: 100%;
    position: relative;
    overflow: hidden;
    @media (max-width: 1024px)  {
        width: 100%;
        height: 25%;
    }
}

.container:hover{
    background: rgba(29, 24, 25, 0.42);
}