.gallery{
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    overflow:auto;
    margin-top: 82px;
    gap: 64px;
    padding-bottom: 200px;
}

.section{
    /*margin: 0 30px;*/
    display: flex;
    width: 100%;
    gap: 10px;
    @media (max-width: 1024px)  {
        gap: 2px;
        flex-direction: column;
    }
}