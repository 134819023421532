.home {
  display: flex;
  width: 100%;
  height: 100%;

  background-image: url('../../assets/images/fidelion-bg.jpg');
  background-size: cover;

  -webkit-transition: background-image 0.3s ease-in-out;
  transition: background-image 0.3s ease-in-out;
  overflow: hidden;
  @media (max-width: 1024px) {
    flex-direction: column;
  }
}
